
.page-header {
  margin-top: 80px;
}

.news {
  .news__list {
    margin-top: 0;
  }

  @media only screen and (max-width: 576px) {
    .news__list__column {
      max-width: 258px;
    }
  }
}

.testimonials {

  .testimonials__slider {
    padding-left: 60px;
    max-width: 1440px;
    margin: unset;

  }

  .testimonials__inner {
    overflow: hidden;


    .testimonials__slider {
      margin-right: auto;
      margin-left: auto;
    }


  }

  .swiper {
    overflow: unset
  }

  .swiper-wrapper {
    margin: auto;
    max-width: 1440px;
  }

  .swiper-slide {
    max-width: 867px;
    display: flex;
    height: unset;
  }

  .testimonial {
    padding-left: unset;
  }

  .testimonial__inner {
    padding: 24px;
    gap: 40px;

  }

  .testimonial__body {
    position: relative;
  }

  .testimonial--backquote {
    margin-bottom: 24px;
  }

  .testimonial__image {
    margin: 0;
  }

  .testimonial__footer {
    margin-top: 24px;
    padding-left: 0;
    border: 0;

    .h3 {
      text-transform: unset;
      margin-bottom: 4px;
      font-size: 18px;
      line-height: 24px;
    }

    .small {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
    }
  }


  @media only screen and (max-width: 1199px) {
    .testimonial {
      max-width: unset;
      padding-top: 0;
    }
    .testimonial__inner {
      flex-direction: row;
    }
    .swiper-slide {
      max-width: 600px;
    }
  }
  @media only screen and (max-width: 991px) {
    .testimonials__slider {
      padding-left: 32px;
    }
  }
  @media only screen and (max-width: 767px) {
    .testimonials__slider {
      padding-left: 16px;

    }
  }

  @media only screen and (max-width: 630px) {
    .testimonials__slider {
      padding-right: 16px;

    }
    .swiper-slide {
      width: calc(100vw - 32px) !important;
    }
  }

  @media only screen and (max-width: 576px) {
    .testimonial__inner {
      flex-direction: column;
    }

    .testimonials-control {
      display: none;
    }
    .testimonial__image {
      width: unset;
      //max-height: 384px;
      img {
        max-width: 100%;
        height: unset;
      }
    }
  }

}

.people-main {

  .page-header {
    margin-top: 0;
  }

  .page-header__title {
    max-width: 720px;
    margin-right: auto;
    margin-left: auto;
  }

  .page-header__img {
    height: 448px;
    max-height: unset;
  }

  .page-header__inner {
    padding: 1.5rem 1.5rem 1.5rem;
  }

  @media only screen and (max-width: 767px) {
    margin-top: 80px;
  }

  //sections


}

@media only screen and (max-width: 767px) {
  body:has(.people-main) {

  }
}


.prog-intership {
  background-color: var(--ground-3);

  @media only screen and (max-width: 767px) {
    &:not(:has(.video__poster)) .text-section__imagery {
      height: unset;

      img {
        position: unset;
      }
    }
  }

  @media only screen and (max-width: 430px) {
    .btn-grey-border {
      max-width: calc(100vw - 32px);
    }
  }
}

.career {
  overflow: unset;
  margin: 0;

  .career__tabs {
    gap: 24px;
    margin-top: 0;
    margin-bottom: 24px;

    .btn {
      padding: 13px 20px;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;

      &.active {
        background-color: var(--body-text);
        color: #fff;
      }
    }
  }

  .career__column:nth-child(2) {
    padding: unset;
  }

  .career__inner {
    display: flex;
    gap: 40px;
    height: 100%;
  }

  .career__apply-block {

  }

  .career__column-right {
    padding: unset !important;
    height: unset;
    display: flex;
    background-color: unset;
    align-items: unset;
    //align-self: flex-start;
    max-width: 527px;
    width: 100%;

    &:before {
      display: none;
    }

  }

  .career__apply-block {
    display: flex;
    max-width: unset;
    padding: 92px 48px !important;
    position: sticky;
    background-color: var(--primary);
    top: 100px;
    margin: unset;
    height: fit-content;
  }

  .career__apply-block__img {
    width: 119px;
    bottom: 0;
    top: unset;
  }

  .career__column-tabs {
    max-width: 753px;
    width: 100%;
    padding-right: 0;
  }

  .accordion-body {
    p {
      margin-bottom: 24px;
    }

    ul {
      font-weight: 400;
    }
  }


  @media only screen and (max-width: 991px) {
    .career__inner {
      flex-direction: column;
      gap: 64px;
    }
    .career__column-tabs {
      max-width: unset;
      padding-bottom: 0;
    }

    .career__column-right {
      max-width: unset;
      margin: unset;
    }

    .career__apply-block {
      width: 100%;
      padding: 54px 24px !important;

      p {
        max-width: 500px;
      }
    }
    .accordion {
      gap: 24px;
    }
  }

  @media only screen and (max-width: 767px) {
    .career__inner {
      gap: 40px;
    }
    .accordion-body {
      padding: 0 0 24px;

      .btn {
        max-width: 328px;
        width: 100%;
      }
    }
  }
  @media only screen and (max-width: 692px) {
    a.btn{
      margin-top: 16px;
    }
  }

  @media only screen and (max-width: 567px) {
    .career__tabs {
      overflow: auto;
      max-width: 100vw;
      padding-bottom: 24px;
      margin-bottom: -8px;
    }
  }

  @media only screen and (max-width: 430px) {

    .career__tabs {
      flex-direction: column;
      padding-bottom: 40px;
      gap: 24px;
      .btn{
        width: 100%;
      }

    }
    .career__apply-block__inner {
      .btn-white-border {
        width: 100%;
        justify-content: center;
      }
    }
    .accordion-body{
      .btn {
        max-width: 416px;
      }
    }
  }
}


.modal-apply {

  .modal-header .btn-close {
    margin-right: -8px;
  }

  .input {
    &:has(input:focus) {
      .form-floating input {
        border: 1px solid var(--primary);
      }

      .input-group {
        border: 1px solid var(--primary);

        .form-floating input {
          border: none;
        }
      }

      label {
        color: var(--subtitle-text);
        font-size: 12px;
        line-height: 16px;
      }

      .form-control::placeholder {
        color: var(--subtitle-text2);
      }

    }


  }


  .input_file.input_grey {
    input {
      border-right: none;
    }

    .input-group .btn {
      background-color: transparent;
      border-left: none;
    }
  }

  .modal-header {
    padding: 24px;
  }

  .modal-body {
    padding: 0 24px 24px;
  }

  .form-actions {
    .btn {
      padding: 20px;
      width: 100%;
    }
  }
}