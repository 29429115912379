
.page-stock {
  .page-stock-wrap {
    overflow: auto;
  }

  .page-stock__inner {
    width: 100%;
    min-width: 900px;
  }

  .page-stock__action {
    .btn:hover {
      svg {
        color: white;
      }
    }
  }

  .page-stock__list__label {
    font-size: 16px;
    line-height: 22px;
  }

  .page-stock__list__value {
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
  }

  .page-stock__logo__img {
    height: 64px;
  }

  .page-stock__list__item {
    max-width: 220px;
    width: 100%;
    justify-content: unset;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 1px;
      background-color: rgba(13, 18, 29, 0.1);
    }

  }

  @media only screen and (max-width: 1050px) {
    padding: 0;
    .page-stock-wrap {
      padding-top: 18px;
      padding-bottom: 18px;
    }
  }

  @media only screen and (max-width: 767px) {


    .page-stock__list__item {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .page-stock__inner {
      display: flex;
      justify-content: space-between;
      gap: 0;
    }
    .page-stock__logo {
      order: 1;
      margin-right: 1.5rem;
    }
    .page-stock__action {
      order: 3;
      margin-left: 1.5rem;


    }

    .page-stock-wrap {
      scroll-snap-type: x proximity;
      scrollbar-width: none;
      scroll-padding-left: 1.5rem;
      scroll-padding-right: 1.5rem;
    }

    .page-stock__inner {

    }

    .page-stock__list {

      order: 2;
      display: flex;
      justify-content: space-between;
      flex: 1;
    }
    .page-stock__list__sep {
      border-top: 0;
      border-left: 1px solid #E4E6EB;
    }
    .page-stock__list__item__inner {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
  }
}

.investors {
  p {
    color: var(--body-text);
  }


  @media (min-width: 992px) {
    .text-section__inner {
      grid-template-columns: auto 47.8%;
    }
  }

  @media only screen and (max-width: 767px) {


    .download-btns-list {
      margin-bottom: 16px;
    }


  }

}

.download-btns-list {
  gap: 24px;
  display: flex;
  flex-wrap: wrap;

  a {
    width: 142px;
    height: 140px;
    padding: 16px;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    font-weight: 500;
    color: var(--body-text);
    //text-transform: lowercase;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='142' height='140' viewBox='0 0 142 140' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='142' height='140' fill='%23F6F7F8'/%3E%3Cpath d='M142 0H102L142 39V0Z' fill='white'/%3E%3Cpath d='M112 30L133 30L112 10L112 30Z' fill='%23A0A7BC'/%3E%3C/svg%3E%0A");

    &:hover {
      text-decoration: none;
      color: var(--primary);
    }


    span {
      margin-top: auto;
      //display: flex;
      display: inline-block;
      text-transform: lowercase;
      &::first-letter{
        text-transform: uppercase;
      }
    }
  }

  @media only screen and (max-width: 506px) {
    .btn-list__item{
      width: calc(50% - 12px);

    }
    a {
      width: 100%;
      background-size: 100%;
    }
  }

}

.exchange-section {

  background-color: var(--ground-3);

  .exchange__item {
    background-color: white;
  }

  .exchange__right {
    background-color: white;
  }

  .exchange__item__label {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: var(--body-text);
  }

  .exchange__item__value {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
  }

  .exchange__block {
    text-align: left;

    .exchange__time {
      font-size: 16px;
      line-height: 22px;
      font-weight: 500;
      color: var(--body-text);
    }

    .h2 {
      font-size: 36px;
      line-height: 44px;
      margin-bottom: 8px;
      color: var(--body-text) !important;
    }

    .icon {

    }

  }

  .exchange__change {
    .h2 {
      margin-left: 16px;
    }
  }

  .exchange__sumary {
    .exchange__row {
      span {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;

      }
    }
  }

//, #Emitent-tab
  .table-tranz-simbol {
    td {
      padding: 16px;
      background-color: #fff;
      font-size: 16px;
      line-height: 22px;
      border: 8px solid var(--ground-3) !important;

      b {
        font-weight: 700;

      }
    }
  }

  .table-inverstors {
    th {
      padding: 16px 12px;
      text-align: left;
      font-size: 12px;
      line-height: 16px;
      font-weight: 700;
      border: 1px solid rgba(255, 255, 255, 0.1)

    }

    td {
      padding: 16px 12px;
      text-align: left;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
    }

    tr:nth-child(even) {
      background: rgba(32, 47, 97, 0.1)
    }
  }

  //.tab-pane:has(:not(.table-wrapper.table-inverstors)) table{
  //  background-color: #fff !important;
  //
  //}

  .pagination {
    margin-bottom: 0;
    margin-top: 32px;

    a {
      color: var(--body-text);

      &.active {
        color: var(--primary);
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .exchange__right .exchange__block {
      text-align: center;
    }
    .pagination {
      margin-top: 40px;
    }
  }
  @media only screen and (max-width: 576px) {


    .exchange__sumary {
      flex-direction: column;
    }
  }

}

.inv-calendar {
  margin: 0;

  h2 br {
    display: none;
  }

  .inv-calendar__header {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 48px;

    .leading {
      font-weight: 400;
      max-width: unset;
    }
  }

  .inv-calendar__category {
    border-top: unset;

    .h3 {
      border-top: unset;
      font-size: 24px;
      line-height: 32px;
    }
  }

  .inv-calendar__content {
    gap: 40px;
  }

  .panel-item {
    .h3 {
      font-size: 18px;
      line-height: 24px;
      font-weight: 500;
      color: var(--body-text);
    }
  }

  .panel-item__date {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: var(--subtitle-text2);
    margin-bottom: 8px;
  }

  @media (min-width: 1400px) {
    .inv-calendar__inner__row {
      gap: 24px;
    }
  }
  @media (min-width: 768px) {
    .inv-calendar__inner__row {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (min-width: 768px) {
    .inv-calendar__content {
      grid-column: span 1;
    }
  }

  @media only screen and (max-width: 767px) {
    .inv-calendar__header {
      text-align: left;
    }


  }
}

.governance {

  background-color: var(--ground-3);

  .governance__header {
    max-width: unset;
  }


  .image-panel__subtitle {
    color: var(--subtitle-text2);
    font-size: 14px;
    text-transform: unset;
  }

  @media only screen and (max-width: 767px) {

    .governance__grid {
      display: flex;
      gap: 40px;
    }

    .image-panel_slim {
      flex-direction: column;
      align-items: unset;
    }
    .image-panel_slim .image-panel__image {
      width: 256px;
      margin: 0;
    }
    .image-panel_slim .image-panel__content {
      margin-top: 24px;
    }

    .tab-pane {
      max-width: 100vw;
      overflow: auto;
      scroll-snap-type: x proximity;
      scrollbar-width: none;
      scroll-padding-left: 1.5rem;
      scroll-padding-right: 1.5rem;
    }


  }
}

.shareholder {
  margin: 0;

  .shareholder__inner {
    gap: 0;
    display: flex;
    flex-direction: column;
  }

  .panel-item {
    max-width: 300px;

    .h3 {
      font-size: 18px;
      line-height: 24px;
      font-weight: 500;
      color: var(--body-text);
    }
  }

  .panel-item__date {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: var(--subtitle-text2);
    margin-bottom: 8px;
  }

  .shareholder__slider {
    padding: 0;
  }

  .shareholder__items {
    display: flex;
    gap: 40px;
  }

  @media only screen and (max-width: 767px) {
    .shareholder__items {
      flex-direction: column;
      max-width: 328px;
    }
    .panel-item {
      width: 100%;
      max-width: 400px;
    }
  }
}

.certifications {
  margin: 0;
  background-color: var(--ground-3);
  @media only screen and (max-width: 576px) {
   .certifications-control{
     display: none;
   }
  }
}

.analyst {
  margin: 0;


  @media only screen and (max-width: 767px) {
    .analyst__inner {
      flex-wrap: wrap;
      gap: 40px;
      margin: unset;
      padding: unset;
    }

    .analyst__image {
      width: calc(100% / 2 - 20px);
    }
  }

}

.relations {
  margin: 0;

  .relations__contacts {
    margin-top: 0;
    gap: 16px;

  }

  .relation-contact-form {
    max-width: 753px;
    width: 100%;

    .input_grey {
      &:not(:last-child) {
        margin-bottom: 16px;
      }

      input {
        height: unset;
      }

      input, textarea {
        background-color: #fff;
        padding: 20px 16px;
        line-height: 22px;
      }

      &:hover {
        label {
          color: var(--subtitle-text);
        }
      }

      label {
        color: var(--subtitle-text2);
        font-weight: 400;
        transition: .3s;
        padding: 20px 16px;
        font-size: 16px;
        line-height: 22px;
      }

      &:has(input:focus), &:has(textarea:focus) {
        label {
          transform: scale(1) translateY(-16px) translate(0);
          font-size: 12px;
          line-height: 16px;
        }
      }

      .form-control:not(:placeholder-shown) ~ label {
        transform: scale(1) translateY(-16px) translate(0);
        font-size: 12px;
        line-height: 16px;
      }

      textarea {
        resize: none;
      }
    }

    .wpcf7-submit {
      background-color: var(--primary);
      padding: 20px 24px;

      &:hover {
        background-color: var(--primary-hover);
      }
    }

    .wpcf7-textarea{
      height: 167px !important;
      @media only screen and (max-width: 767px) {
        height: 176px !important;
      }
    }
  }

  .contact-item__list {


  }

  .contact-item {
    padding: 24px;
    background-color: var(--ground-3);

    h3 {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 16px;
    }

    .contact-item__link {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
  }

  .relations__inner {
    display: flex;
    gap: 40px;
  }

  .relations__column {
    flex-grow: 1;
  }

  .relations__contacts {
    display: flex;
    flex-direction: column;
  }



  @media only screen and (max-width: 767px) {
    .relations__inner {
      flex-direction: column;
    }
  }
}
