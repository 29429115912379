
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0 24px 20px;

  overflow: auto;
  @media only screen and (max-width: 767px) {
    padding: 0 0 20px;
  }

  .page-item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    width: 48px;
    height: 48px;

    @media only screen and (max-width: 1024px) {
      width: 40px;
      height: 40px;
      .page-link {
        width: 40px;
        height: 40px;
      }
      &:hover {
        .page-link {

        }
      }
    }

    &:before {
      display: none;
    }

    .page-link {
      width: 100%;
      padding: 6px;
      font-size: 14px;
      line-height: 20;
      font-weight: 500;

      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--body-text);
      margin: 0;
      text-align: center;
      border: 1px solid transparent;
      text-decoration: none;
    }

    &.pagination-prev-nav, &.pagination-next-nav, &.pagination-first-page, &.pagination-last-page {

      .page-link {
        display: flex;
        justify-content: center;
        align-items: center;
        background-size: 18px;
        background-repeat: no-repeat;
        background-position: center;

        svg {
          width: 18px;
          height: 18px;
        }
      }

      span {

      }
    }

    &.pagination-prev-nav {

    }

    &.pagination-next-nav {

    }


    &.disabled {
      &.pagination-prev-nav, &.pagination-next-nav, &.pagination-first-page, &.pagination-last-page {
        border-color: transparent;
        opacity: .7;

        .page-link {

          .svg {
            color: #e4e8ed;
          }
        }
      }

    }

    &.active {
      @media only screen and (max-width: 1024px) {
        &:hover {

          .page-link {

          }
        }
      }

      .page-link {
        color: var(--primary);
        font-weight: 700;
      }
    }


    &:nth-last-child(-n + 2) {
      //margin-left: 8px;
    }

    &:nth-child(-n + 2) {
      //margin-right: 8px;
    }

  }


}
