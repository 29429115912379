:root {
  --primary: #202F61;
  --primary-hover: rgba(29, 42, 87, 1);
  --secondary: rgba(190, 150, 110, 1);
  --body-text: rgba(39, 39, 40, 1);
  --ground-3: rgba(246, 247, 248, 1);
  --black-t10: rgba(10, 9, 8, 0.1);
  --subtitle-text2: rgba(39, 39, 40, 0.5);
  --dark-accent: rgba(10, 9, 8, 1);
  --white: rgba(255, 255, 255, 1);
  --white-t10: rgba(255, 255, 255, 0.1);
  --white-t50: rgba(255, 255, 255, 0.5);
  --white-t70: rgba(255, 255, 255, 0.7);
  --subtitle-white: rgba(255, 255, 255, 0.7) ;
}

.c-red {
  color: rgb(224, 40, 43);
}

.c-green {
  color: rgb(81, 193, 79);
}

body {
  background-color: #fff;
  color: var(--body-text);
  overflow-x: hidden;
}

.grecaptcha-badge {
  position: fixed;
  right: 0;
  visibility: hidden;
  width: unset;
}

.btn {
  border-radius: 0;
  padding: 12px 12px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
}

.btn-grey-border, .btn-white-border {
  padding: 12px 12px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  align-self: center;
  display: flex;
  align-items: center;
  line-height: 22px;
  gap: 12px;
  transition: 0.3s;
}
.btn-grey-border:hover, .btn-white-border:hover {
  color: #fff;
  background-color: var(--primary-hover);
  text-decoration: none;
}

.btn-grey-border {
  width: max-content;
  padding: 12px 12px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  border: 1px solid var(--black-t10);
  align-self: center;
  align-items: center;
  line-height: 22px;
  gap: 12px;
  color: var(--body-text);
  transition: 0.3s;
}
.btn-grey-border:hover {
  color: #fff;
  background-color: var(--primary-hover);
  text-decoration: none;
}
.btn-grey-border span {
  margin: 0 !important;
  padding: 0 !important;
  border: 0 !important;
}

.btn-read-more {
  padding: 20px 24px;
}
@media only screen and (max-width: 767px) {
  .btn-read-more {
    max-width: 328px;
    width: 100%;
    justify-content: center;
  }
}

.btn-white-border {
  width: max-content;
  border: 1px solid var(--white-t50);
  padding: 12px 20px;
  color: var(--white);
  background-color: transparent;
}
.btn-white-border:hover {
  color: var(--body-text);
  background-color: var(--white);
}
.btn-white-border span {
  margin: 0 !important;
  padding: 0 !important;
  border: 0 !important;
}

.btn-primary {
  background-color: var(--primary);
  color: #fff;
}
.btn-primary:hover {
  background-color: var(--primary-hover);
  color: #fff;
}

.mra {
  margin-right: auto;
}

.mla {
  margin-left: auto;
}

.section {
  padding-top: 80px;
  padding-bottom: 80px;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.section .h2 {
  font-size: 36px;
  line-height: 44px;
}
.section:not(:has(.section-header)) .h2 {
  margin-bottom: 48px;
}
@media only screen and (max-width: 1199px) {
  .section {
    padding-top: 64px;
    padding-bottom: 64px;
  }
}
@media only screen and (max-width: 991px) {
  .section {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .section:not(:has(.section-header)) .h2 {
    margin-bottom: 24px;
  }
}
@media only screen and (max-width: 767px) {
  .section .h2 {
    font-size: 32px;
    line-height: 40px;
  }
}

.section-header {
  margin-bottom: 48px;
}
.section-header .section-subtitle {
  margin-top: 16px;
}
.section-header .h2 {
  margin-bottom: 0;
}
.section-header:has(p) .h2 {
  margin-bottom: 16px;
}
.section-header:has(.swiper-slider-control) {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (max-width: 991px) {
  .section-header {
    margin-bottom: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .text-section:not(:has(.video__poster)) .text-section__imagery {
    height: 480px;
    position: relative;
    overflow: hidden;
  }
  .text-section:not(:has(.video__poster)) .text-section__imagery img {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 660px;
    height: 100%;
    object-fit: cover;
  }
}

@media only screen and (min-width: 992px) {
  .text-section__inner {
    align-items: unset;
  }
  .h2 {
    font-size: 36px;
    line-height: 44px;
  }
  .text-section__text p {
    color: var(--body-text);
  }
}
.container-base {
  max-width: 1440px;
  padding-left: 60px;
  padding-right: 60px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}
@media only screen and (max-width: 1199px) {
  .container-base {
    padding-left: 48px;
    padding-right: 48px;
  }
}
@media only screen and (max-width: 991px) {
  .container-base {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media only screen and (max-width: 767px) {
  .container-base {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.container-nopad {
  max-width: 1368px;
  margin: 0 auto;
}

.input .form-floating input, .input .form-floating select, .input .form-floating textarea {
  background-color: transparent;
  height: 64px !important;
  padding: 21px 16px;
  border: 1px solid var(--black-t10);
  border-radius: 0;
}
.input .form-floating select + label {
  display: none;
}
.input .form-floating label {
  font-size: 16px;
  font-weight: 400;
  padding-top: 22px;
  padding-left: 16px;
  text-transform: initial;
}
.input .form-floating:has(input:not(:placeholder-shown)) label {
  color: var(--subtitle-text);
  font-size: 12px;
  line-height: 16px;
}

.tabs-wrapper {
  border: none;
}
.tabs-wrapper .tab-content {
  margin-top: 32px;
}

.tabs-wrapper .nav-tabs .nav-link {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  text-transform: lowercase;
  color: var(--subtitle-text2);
  padding: 6px 0;
}
.tabs-wrapper .nav-tabs .nav-link::first-letter {
  text-transform: uppercase;
}
.swiper-button {
  position: relative;
  top: 13px;
  right: unset;
  left: unset;
  height: 48px;
  width: 48px;
}
.swiper-button svg {
  stroke: #272728;
}
.swiper-button:hover {
  stroke: white;
}

.swiper-slider-control {
  display: flex;
  gap: 8px;
}

.accordion-button {
  background-color: var(--ground-3);
  font-weight: 500;
  text-transform: initial;
  padding: 16px;
}
.accordion-button:not(.collapsed) {
  background-color: var(--ground-3);
}
.accordion-button:after {
  width: 32px;
  height: 32px;
  background-size: 32px;
  background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 12L16 20L24 12' stroke='%23272728' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}
.accordion-button:not(.collapsed):after {
  background-size: 32px;
  background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 12L16 20L24 12' stroke='%23272728' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  transform: rotate(-180deg);
}

.accordion-collapse {
  padding-top: 24px;
}

body #moove_gdpr_cookie_info_bar {
  border-radius: 0 !important;
  left: unset !important;
  right: 16px !important;
  border: 1px solid #e2e2e2 !important;
}
body #moove_gdpr_cookie_info_bar .moove-gdpr-info-bar-container .moove-gdpr-info-bar-content a.mgbutton, body #moove_gdpr_cookie_info_bar .moove-gdpr-info-bar-container .moove-gdpr-info-bar-content button.mgbutton {
  border-radius: 0 !important;
}
body #moove_gdpr_cookie_info_bar .moove-gdpr-info-bar-container .moove-gdpr-info-bar-content .change-settings-button {
  border: 1px solid #d3ced2 !important;
  box-shadow: unset !important;
}
@media only screen and (max-width: 430px) {
  body #moove_gdpr_cookie_info_bar {
    left: 0 !important;
    width: 100% !important;
    max-width: unset !important;
    right: 0 !important;
  }
  body #moove_gdpr_cookie_info_bar .moove-gdpr-info-bar-container .moove-gdpr-info-bar-content a.mgbutton, body #moove_gdpr_cookie_info_bar .moove-gdpr-info-bar-container .moove-gdpr-info-bar-content button.mgbutton {
    padding: 12px 16px !important;
    width: calc(50% - 8px) !important;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 24px 20px;
  overflow: auto;
}
@media only screen and (max-width: 767px) {
  .pagination {
    padding: 0 0 20px;
  }
}
.pagination .page-item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  width: 48px;
  height: 48px;
}
@media only screen and (max-width: 1024px) {
  .pagination .page-item {
    width: 40px;
    height: 40px;
  }
  .pagination .page-item .page-link {
    width: 40px;
    height: 40px;
  }
}
.pagination .page-item:before {
  display: none;
}
.pagination .page-item .page-link {
  width: 100%;
  padding: 6px;
  font-size: 14px;
  line-height: 20;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--body-text);
  margin: 0;
  text-align: center;
  border: 1px solid transparent;
  text-decoration: none;
}
.pagination .page-item.pagination-prev-nav .page-link, .pagination .page-item.pagination-next-nav .page-link, .pagination .page-item.pagination-first-page .page-link, .pagination .page-item.pagination-last-page .page-link {
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: 18px;
  background-repeat: no-repeat;
  background-position: center;
}
.pagination .page-item.pagination-prev-nav .page-link svg, .pagination .page-item.pagination-next-nav .page-link svg, .pagination .page-item.pagination-first-page .page-link svg, .pagination .page-item.pagination-last-page .page-link svg {
  width: 18px;
  height: 18px;
}
.pagination .page-item.disabled.pagination-prev-nav, .pagination .page-item.disabled.pagination-next-nav, .pagination .page-item.disabled.pagination-first-page, .pagination .page-item.disabled.pagination-last-page {
  border-color: transparent;
  opacity: 0.7;
}
.pagination .page-item.disabled.pagination-prev-nav .page-link .svg, .pagination .page-item.disabled.pagination-next-nav .page-link .svg, .pagination .page-item.disabled.pagination-first-page .page-link .svg, .pagination .page-item.disabled.pagination-last-page .page-link .svg {
  color: #e4e8ed;
}
.pagination .page-item.active .page-link {
  color: var(--primary);
  font-weight: 700;
}
.header {
  padding: 0;
}
.header.scrolled, .header.homepage.scrolled {
  background-color: var(--primary);
}
.header.scrolled:before, .header.homepage.scrolled:before {
  content: "";
  position: absolute;
  backdrop-filter: unset;
}
.header .header__logo:hover {
  color: white;
}
.header .header__nav__list {
  gap: 48px;
}
.header .header__nav__item {
  margin-left: 0;
}
.header .header__logo {
  width: 185px;
  margin-right: unset;
}
.header .header__actions {
  width: 185px;
  margin-left: unset;
  display: flex;
  justify-content: flex-end;
}
.header .header__actions .dropdown-menu {
  min-width: 120px;
}
.header .header__actions .dropdown-item.active svg {
  display: none;
}
.header .header__search {
  background-color: #fff;
  padding: 15px 12px 15px 24px;
  margin-left: 0;
  height: 48px;
}
.header .header__search .header__search-ico {
  padding-right: 16px;
  margin-right: 4px;
  display: flex;
}
.header .header__search .input {
  display: flex;
  align-items: center;
}
.header .header__search .input.has-error .form-control {
  border: none;
}
.header .header__search .input label {
  display: none;
}
.header .header__search .input input {
  padding: 0;
  height: 22px;
  font-size: 16px;
  line-height: 22px;
}
.header .header__search .input input::placeholder {
  color: var(--subtitle-text2) !important;
}
.header .dropdown-menu-closer {
  display: none;
}
@media only screen and (max-width: 1199px) {
  .header .header__inner {
    justify-content: space-between;
  }
  .header .header__nav {
    padding: 0;
    width: 260px;
    background-color: var(--primary);
  }
  .header .header-nav-inner {
    height: 100%;
    padding-top: 80px;
  }
  .header .header__nav__item {
    border: none;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .header .header__nav__item:first-child {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .header .header__nav__link {
    justify-content: center;
  }
}
@media only screen and (max-width: 767px) {
  .header.homepage {
    background-color: var(--primary);
  }
  .header .header__nav {
    bottom: -100%;
    transform: unset !important;
    top: unset;
    height: fit-content;
    overflow: unset;
    left: 0 !important;
    right: 0 !important;
    width: 100%;
    max-width: unset;
    transition: 0.3s;
  }
  .header .header-nav-inner {
    padding-top: unset;
    height: 100%;
    transition: 0.3s;
    max-height: calc(100vh - 200px);
    overflow: auto;
  }
  .header .header-nav-inner:has(.header__nav.active) {
    display: none;
  }
  .header .header__nav.active {
    bottom: 0;
    max-height: 80%;
    width: 100% !important;
    max-width: 100%;
    padding-bottom: 24px;
  }
  .header .header__nav__list {
    gap: 24px;
    flex-direction: column;
    display: flex;
  }
  .header .header__nav__close {
    display: flex;
    position: relative;
    height: 37px;
    right: 0;
    width: 100%;
    left: 0;
    top: unset;
  }
  .header .header__nav__close:before {
    content: "";
    position: absolute;
    width: 36px;
    height: 6px;
    border-radius: 10px;
    background-color: var(--white-t50);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }
  .header .header__nav__close svg {
    display: none;
  }
  .header .header__nav__item {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .header .header__actions .dropdown-menu {
    bottom: -100%;
    padding-bottom: 24px;
  }
  .header .header__actions .dropdown-menu:before {
    display: flex;
    position: absolute;
    height: 37px;
    right: 0;
    width: 100%;
    left: 0;
    content: "";
    top: -37px;
    background-color: #fff;
  }
  .header .header__actions .dropdown-menu:after {
    content: "";
    position: absolute;
    width: 36px;
    height: 6px;
    border-radius: 10px;
    background-color: var(--black-t10);
    top: -20px;
    right: 0;
    /* bottom: 0; */
    left: 0;
    margin: auto;
  }
  .header .header__actions .dropdown-menu.show {
    inset: unset !important;
    width: 100% !important;
    left: 0 !important;
    right: 0 !important;
    position: fixed !important;
    transform: unset !important;
    height: fit-content !important;
    bottom: 0 !important;
    flex-direction: column;
    gap: 8px;
  }
  .header .header__actions .dropdown-item {
    text-align: center;
  }
  .header:has(.dropdown-menu.show) .header-backdrop {
    opacity: 1;
    visibility: visible;
  }
  .header .header__search {
    position: fixed;
    left: 0;
    right: 0;
    top: -100%;
    padding: 15px 16px 15px 16px;
  }
  .header .search-open .header__search {
    top: 80px;
  }
  .header .search-open .header__logo {
    display: flex;
  }
  .header .header-backdrop {
    z-index: 0;
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.2);
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
  }
  .header:has(.search-open) .header-backdrop {
    opacity: 1;
    visibility: visible;
  }
}

@media only screen and (max-width: 767px) {
  .header__inner {
    transition: 0.3s;
  }
  .body.menu-open .header__inner {
    margin-top: -100px;
  }
}
@media only screen and (max-width: 767px) {
  body:has(.header__actions .dropdown-menu.show) {
    overflow: hidden;
  }
}
.footer {
  padding-top: 48px;
  padding-bottom: 48px;
}
.footer .footer__row {
  padding-bottom: 48px;
  position: relative;
  margin-top: 0;
}
.footer .footer__row:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 1px;
  background-color: var(--white-t10);
}
.footer .footer-after-logo {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: var(--subtitle-white);
}
.footer .footer__row {
  gap: 40px;
  display: flex;
  width: 100%;
}
.footer .footer__column {
  max-width: 284px;
  flex-grow: 1;
}
.footer .footer__column-main {
  max-width: 349px;
}
.footer .footer__social {
  gap: 16px;
  margin-top: 24px;
  display: flex;
}
.footer .footer__social__link {
  min-width: unset;
  min-height: unset;
  display: flex;
  width: 18px;
  height: 18px;
  color: rgba(255, 255, 255, 0.7);
}
.footer .footer__social__link:hover {
  color: #fff;
}
.footer .footer__menu {
  gap: 16px;
}
.footer .footer__menu__link {
  color: #fff;
  font-size: 16px;
  line-height: 22px;
}
.footer .footer__title {
  color: var(--subtitle-white);
  margin-bottom: 24px;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
}
.footer .footer__contact input {
  background-color: var(--white-t10);
  width: 100%;
  border-bottom: 0;
  height: 48px !important;
  padding: 13px 16px;
}
.footer .footer__contact .input-group {
  position: relative;
  margin-bottom: 0;
  border: 0 !important;
}
.footer .footer__contact .form-floating:has(input:focus) label, .footer .footer__contact .form-floating:has(input:not(:placeholder-shown)) label {
  font-size: 12px;
}
.footer .footer__contact .form-floating > label {
  left: 16px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: var(--subtitle-white);
  padding: 13px 0;
  transition: 0.3s;
}
.footer .footer__contact [type=submit] {
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
  top: 0;
  bottom: 0;
  right: 0;
  width: 48px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.16675 10H15.8334' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M10 4.16699L15.8333 10.0003L10 15.8337' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}
.footer .footer-contacts-us-list {
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.footer .footer-contacts-us-list div {
  display: flex;
  gap: 8px;
}
.footer .footer-contacts-us-list a, .footer .footer-contacts-us-list span {
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}
.footer .footer-contacts-us-list svg {
  flex-shrink: 0;
}
.footer .footer__copyright {
  margin-top: 0;
  color: var(--subtitle-white);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.footer .footer-bottom {
  padding-top: 48px;
  display: flex;
  justify-content: space-between;
}
.footer .footer-bottom a {
  color: var(--subtitle-white);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.footer .footer-by {
  font-size: 14px;
  line-height: 20px;
}
@media only screen and (max-width: 991px) {
  .footer .footer__row {
    flex-wrap: wrap;
    gap: 24px;
  }
  .footer .footer__column {
    width: calc(50% - 16px);
  }
  .footer .footer-col-menu-help {
    width: 100%;
    max-width: unset;
  }
  .footer .footer-col-menu {
    width: 100%;
    max-width: unset;
    order: 1;
  }
  .footer .footer__menu {
    display: flex;
  }
}
@media only screen and (max-width: 650px) {
  .footer .footer__row {
    flex-direction: column;
  }
  .footer .footer__row {
    gap: 40px;
  }
  .footer .footer__column {
    width: 100%;
  }
  .footer .footer-col-menu {
    order: unset;
  }
}
@media only screen and (max-width: 576px) {
  .footer .footer__menu {
    display: initial;
  }
  .footer .footer-bottom {
    flex-direction: column;
    gap: 24px;
    align-items: center;
  }
}

/*/ Section Hero /*/
.hero {
  padding-bottom: 100px;
}
.hero .swiper .btn-primary {
  padding: 20px 24px;
  font-size: 18px;
  line-height: 24px;
}
.hero .h1 {
  font-size: 48px;
  line-height: 56px;
}
.hero .hero__slide__inner {
  gap: 48px;
}
.hero .hero__leading {
  margin-top: 0;
  font-weight: 400;
}
.hero .hero-slide {
  max-width: 868px;
}
@media only screen and (max-width: 767px) {
  .hero .h1 {
    text-transform: initial;
    font-size: 32px;
    line-height: 40px;
  }
  .hero .hero__slide__inner {
    gap: 24px;
  }
  .hero .hero__slide {
    padding-bottom: 142px;
    padding-top: 198px;
  }
}
@media (max-width: 1200px) {
  .hero .hero__slide {
    max-width: 75%;
  }
}
@media (max-width: 767px) {
  .hero .hero__slide {
    max-width: 100%;
  }
}

.hero__stock {
  top: unset;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--primary);
  padding-top: 0;
}
.hero__stock .hero__stock__container {
  width: 100%;
  padding-left: 0;
}
.hero__stock .hero-stock-wrapper {
  overflow: auto;
  display: flex;
}
.hero__stock .hero__stock__header {
  margin-right: 40px;
}
.hero__stock .hero__stock__inner {
  display: flex;
  padding: 8px 0;
  background: unset;
  backdrop-filter: unset;
  width: 1320px;
  flex-shrink: 0;
}
.hero__stock .hero__stock__list {
  display: flex;
  flex-grow: 111;
  margin-top: 0;
  gap: 24px;
}
.hero__stock .hero__stock__list__item {
  display: flex;
  flex-direction: column;
  max-width: 187px;
  width: 100%;
  height: 84px;
  padding: 0;
  justify-content: center;
  position: relative;
  border-bottom: none;
}
.hero__stock .hero__stock__list__item:first-child {
  flex-shrink: 0;
}
.hero__stock .hero__stock__list__item:not(:last-child):after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 1px;
  background-color: rgba(255, 255, 255, 0.1);
}
.hero__stock .hero__stock__list__item span {
  margin: unset;
  text-align: unset;
}
.hero__stock .hero__stock__list__label {
  margin-bottom: 8px !important;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
}
.hero__stock .hero__stock__list__value {
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
}
.hero__stock .hero__stock__btn {
  margin-left: 40px;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.hero__stock .hero__stock__btn:hover svg {
  color: white;
}
.hero__stock .hero__stock__btn span {
  margin: 0;
}

.fin-stats {
  margin-bottom: 0;
  margin-top: 0;
  background-color: var(--ground-3);
}
.fin-stats .h2 {
  margin-bottom: 16px;
}
.fin-stats .fin-stats__inner {
  gap: 40px;
}
.fin-stats .fin-stats__column {
  display: flex;
  flex-direction: column;
}
.fin-stats .fin-stats__column:not(:first-child) {
  background-color: #fff;
  padding: 24px 24px 40px;
}
.fin-stats .btn-grey-border {
  align-self: flex-start;
  padding: 13px 20px;
}
.fin-stats .fin-stats__subtitle {
  font-size: 16px;
  line-height: 22px;
}
@media only screen and (max-width: 1199px) {
  .fin-stats .fin-stats__inner {
    flex-wrap: wrap;
    display: flex;
  }
  .fin-stats .fin-stats__column {
    width: calc(50% - 20px);
  }
}
@media only screen and (max-width: 767px) {
  .fin-stats .fin-stats__inner {
    gap: 24px;
  }
  .fin-stats .fin-stats__inner .fin-stats__column {
    width: calc(50% - 12px);
  }
  .fin-stats .fin-stats__value {
    font-size: 36px;
    line-height: 44px;
  }
}
@media only screen and (max-width: 576px) {
  .fin-stats .fin-stats__inner {
    gap: 40px;
  }
  .fin-stats .fin-stats__inner .fin-stats__column {
    width: 100%;
  }
}

.mission {
  overflow: hidden;
  margin-top: 0;
  margin-bottom: 0;
}
.mission .container-base {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.mission .h2 {
  margin-bottom: 16px;
}
.mission .mission__inner {
  display: flex;
  flex-direction: column;
  align-items: unset;
}
.mission .mission__column_info {
  display: flex;
  width: 100%;
  padding-right: 0;
}
.mission .mission__column_info .mission__block {
  display: flex;
  width: 50%;
  flex-direction: column;
  max-width: unset;
  padding: 48px 60px;
  margin-bottom: 0;
  color: white;
  position: relative;
}
.mission .mission__column_info .mission__block:first-child {
  background-color: var(--primary);
}
.mission .mission__column_info .mission__block:first-child:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: var(--primary);
  width: 50vw;
  z-index: 0;
  left: -50vw;
}
.mission .mission__column_info .mission__block:nth-child(2) {
  background-color: var(--secondary);
}
.mission .mission__column_info .mission__block:nth-child(2):after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: var(--secondary);
  width: 50vw;
  z-index: 0;
  right: -50vw;
}
@media (min-width: 768px) {
  .mission .mission__block {
    margin-bottom: unset;
  }
}
@media only screen and (max-width: 1199px) {
  .mission .mission__column_info .mission__block {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media only screen and (max-width: 767px) {
  .mission .mission__column_info {
    flex-direction: column;
  }
  .mission .mission__column_info .mission__block {
    width: 100%;
    padding: 48px 16px;
  }
}

.our-values .mission__list {
  background-color: unset;
  padding: 0;
}
.our-values .mission__list ul {
  display: flex;
  width: 100%;
  max-width: unset;
  gap: 24px;
}
.our-values .mission__list li {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  background-color: unset;
  margin-bottom: 0;
}
.our-values .mission__list li span {
  font-size: 48px;
  line-height: 56px;
  font-weight: 700;
  color: var(--secondary);
  display: block;
  margin-bottom: 24px;
}
.our-values .mission__list li:before {
  display: none;
}
.our-values .mission__list li strong {
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: 700;
}
@media only screen and (max-width: 1199px) {
  .our-values .mission__list ul {
    display: flex;
    flex-wrap: wrap;
  }
  .our-values .mission__list li {
    max-width: calc(50% - 20px);
    width: 100%;
  }
}
@media only screen and (max-width: 576px) {
  .our-values .mission__list ul {
    flex-direction: column;
  }
  .our-values .mission__list li {
    width: 100%;
    max-width: unset;
  }
}

.awards {
  margin-top: 0;
  margin-bottom: 0;
}
.awards .awards__slider {
  margin: unset !important;
}
.awards .awards__slider .awards__img {
  transition: 0.3s;
}
.awards .awards__slider .swiper-slide {
  overflow: hidden;
  width: unset;
}
.awards .awards__slider .awards__img:hover {
  transform: scale(1.1);
}
@media only screen and (max-width: 767px) {
  .awards .awards__img {
    height: 328px !important;
    width: 100%;
  }
}

.news {
  margin-top: 0;
  margin-bottom: 0;
  background-color: var(--ground-3);
}
.news .news__list {
  display: flex;
  gap: 40px;
  overflow: auto;
}
.news .news__list__column {
  max-width: 300px;
  width: 100%;
  flex-shrink: 0;
}
.news .news__item__date {
  color: var(--subtitle-text2);
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 8px;
}
.news .news__item__title {
  font-size: 18px;
  line-height: 20px;
  font-weight: 500;
}
.news .btn-more-news {
  margin-right: auto;
  margin-left: auto;
  width: max-content;
  padding: 20px 24px;
  font-size: 18px;
  line-height: 24px;
}
@media only screen and (max-width: 767px) {
  .news .tab-content {
    overflow: hidden;
  }
  .news .btn-more-news {
    width: 100%;
    max-width: 328px;
  }
}

.membership {
  margin: 0;
}
@media only screen and (max-width: 767px) {
  .membership .membership__inner {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    margin: unset;
    padding: unset;
  }
  .membership .membership__column {
    width: calc(50% - 20px);
    min-height: 50px;
  }
}

.main-page-home .logotypes {
  background-color: var(--ground-3);
  padding: 24px 0;
  overflow: hidden;
}
.main-page-home .logotypes .logotypes__inner {
  overflow: auto;
}

.page-stock .page-stock-wrap {
  overflow: auto;
}
.page-stock .page-stock__inner {
  width: 100%;
  min-width: 900px;
}
.page-stock .page-stock__action .btn:hover svg {
  color: white;
}
.page-stock .page-stock__list__label {
  font-size: 16px;
  line-height: 22px;
}
.page-stock .page-stock__list__value {
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
}
.page-stock .page-stock__logo__img {
  height: 64px;
}
.page-stock .page-stock__list__item {
  max-width: 220px;
  width: 100%;
  justify-content: unset;
  position: relative;
}
.page-stock .page-stock__list__item:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 1px;
  background-color: rgba(13, 18, 29, 0.1);
}
@media only screen and (max-width: 1050px) {
  .page-stock {
    padding: 0;
  }
  .page-stock .page-stock-wrap {
    padding-top: 18px;
    padding-bottom: 18px;
  }
}
@media only screen and (max-width: 767px) {
  .page-stock .page-stock__list__item {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .page-stock .page-stock__inner {
    display: flex;
    justify-content: space-between;
    gap: 0;
  }
  .page-stock .page-stock__logo {
    order: 1;
    margin-right: 1.5rem;
  }
  .page-stock .page-stock__action {
    order: 3;
    margin-left: 1.5rem;
  }
  .page-stock .page-stock-wrap {
    scroll-snap-type: x proximity;
    scrollbar-width: none;
    scroll-padding-left: 1.5rem;
    scroll-padding-right: 1.5rem;
  }
  .page-stock .page-stock__list {
    order: 2;
    display: flex;
    justify-content: space-between;
    flex: 1;
  }
  .page-stock .page-stock__list__sep {
    border-top: 0;
    border-left: 1px solid #E4E6EB;
  }
  .page-stock .page-stock__list__item__inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
}

.investors p {
  color: var(--body-text);
}
@media (min-width: 992px) {
  .investors .text-section__inner {
    grid-template-columns: auto 47.8%;
  }
}
@media only screen and (max-width: 767px) {
  .investors .download-btns-list {
    margin-bottom: 16px;
  }
}

.download-btns-list {
  gap: 24px;
  display: flex;
  flex-wrap: wrap;
}
.download-btns-list a {
  width: 142px;
  height: 140px;
  padding: 16px;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  font-weight: 500;
  color: var(--body-text);
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg width='142' height='140' viewBox='0 0 142 140' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='142' height='140' fill='%23F6F7F8'/%3E%3Cpath d='M142 0H102L142 39V0Z' fill='white'/%3E%3Cpath d='M112 30L133 30L112 10L112 30Z' fill='%23A0A7BC'/%3E%3C/svg%3E%0A");
}
.download-btns-list a:hover {
  text-decoration: none;
  color: var(--primary);
}
.download-btns-list a span {
  margin-top: auto;
  display: inline-block;
  text-transform: lowercase;
}
.download-btns-list a span::first-letter {
  text-transform: uppercase;
}
@media only screen and (max-width: 506px) {
  .download-btns-list .btn-list__item {
    width: calc(50% - 12px);
  }
  .download-btns-list a {
    width: 100%;
    background-size: 100%;
  }
}

.exchange-section {
  background-color: var(--ground-3);
}
.exchange-section .exchange__item {
  background-color: white;
}
.exchange-section .exchange__right {
  background-color: white;
}
.exchange-section .exchange__item__label {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: var(--body-text);
}
.exchange-section .exchange__item__value {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.exchange-section .exchange__block {
  text-align: left;
}
.exchange-section .exchange__block .exchange__time {
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  color: var(--body-text);
}
.exchange-section .exchange__block .h2 {
  font-size: 36px;
  line-height: 44px;
  margin-bottom: 8px;
  color: var(--body-text) !important;
}
.exchange-section .exchange__change .h2 {
  margin-left: 16px;
}
.exchange-section .exchange__sumary .exchange__row span {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.exchange-section .table-tranz-simbol td {
  padding: 16px;
  background-color: #fff;
  font-size: 16px;
  line-height: 22px;
  border: 8px solid var(--ground-3) !important;
}
.exchange-section .table-tranz-simbol td b {
  font-weight: 700;
}
.exchange-section .table-inverstors th {
  padding: 16px 12px;
  text-align: left;
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  border: 1px solid rgba(255, 255, 255, 0.1);
}
.exchange-section .table-inverstors td {
  padding: 16px 12px;
  text-align: left;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}
.exchange-section .table-inverstors tr:nth-child(even) {
  background: rgba(32, 47, 97, 0.1);
}
.exchange-section .pagination {
  margin-bottom: 0;
  margin-top: 32px;
}
.exchange-section .pagination a {
  color: var(--body-text);
}
.exchange-section .pagination a.active {
  color: var(--primary);
}
@media only screen and (max-width: 767px) {
  .exchange-section .exchange__right .exchange__block {
    text-align: center;
  }
  .exchange-section .pagination {
    margin-top: 40px;
  }
}
@media only screen and (max-width: 576px) {
  .exchange-section .exchange__sumary {
    flex-direction: column;
  }
}

.inv-calendar {
  margin: 0;
}
.inv-calendar h2 br {
  display: none;
}
.inv-calendar .inv-calendar__header {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 48px;
}
.inv-calendar .inv-calendar__header .leading {
  font-weight: 400;
  max-width: unset;
}
.inv-calendar .inv-calendar__category {
  border-top: unset;
}
.inv-calendar .inv-calendar__category .h3 {
  border-top: unset;
  font-size: 24px;
  line-height: 32px;
}
.inv-calendar .inv-calendar__content {
  gap: 40px;
}
.inv-calendar .panel-item .h3 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  color: var(--body-text);
}
.inv-calendar .panel-item__date {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: var(--subtitle-text2);
  margin-bottom: 8px;
}
@media (min-width: 1400px) {
  .inv-calendar .inv-calendar__inner__row {
    gap: 24px;
  }
}
@media (min-width: 768px) {
  .inv-calendar .inv-calendar__inner__row {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 768px) {
  .inv-calendar .inv-calendar__content {
    grid-column: span 1;
  }
}
@media only screen and (max-width: 767px) {
  .inv-calendar .inv-calendar__header {
    text-align: left;
  }
}

.governance {
  background-color: var(--ground-3);
}
.governance .governance__header {
  max-width: unset;
}
.governance .image-panel__subtitle {
  color: var(--subtitle-text2);
  font-size: 14px;
  text-transform: unset;
}
@media only screen and (max-width: 767px) {
  .governance .governance__grid {
    display: flex;
    gap: 40px;
  }
  .governance .image-panel_slim {
    flex-direction: column;
    align-items: unset;
  }
  .governance .image-panel_slim .image-panel__image {
    width: 256px;
    margin: 0;
  }
  .governance .image-panel_slim .image-panel__content {
    margin-top: 24px;
  }
  .governance .tab-pane {
    max-width: 100vw;
    overflow: auto;
    scroll-snap-type: x proximity;
    scrollbar-width: none;
    scroll-padding-left: 1.5rem;
    scroll-padding-right: 1.5rem;
  }
}

.shareholder {
  margin: 0;
}
.shareholder .shareholder__inner {
  gap: 0;
  display: flex;
  flex-direction: column;
}
.shareholder .panel-item {
  max-width: 300px;
}
.shareholder .panel-item .h3 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  color: var(--body-text);
}
.shareholder .panel-item__date {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: var(--subtitle-text2);
  margin-bottom: 8px;
}
.shareholder .shareholder__slider {
  padding: 0;
}
.shareholder .shareholder__items {
  display: flex;
  gap: 40px;
}
@media only screen and (max-width: 767px) {
  .shareholder .shareholder__items {
    flex-direction: column;
    max-width: 328px;
  }
  .shareholder .panel-item {
    width: 100%;
    max-width: 400px;
  }
}

.certifications {
  margin: 0;
  background-color: var(--ground-3);
}
@media only screen and (max-width: 576px) {
  .certifications .certifications-control {
    display: none;
  }
}

.analyst {
  margin: 0;
}
@media only screen and (max-width: 767px) {
  .analyst .analyst__inner {
    flex-wrap: wrap;
    gap: 40px;
    margin: unset;
    padding: unset;
  }
  .analyst .analyst__image {
    width: calc(50% - 20px);
  }
}

.relations {
  margin: 0;
}
.relations .relations__contacts {
  margin-top: 0;
  gap: 16px;
}
.relations .relation-contact-form {
  max-width: 753px;
  width: 100%;
}
.relations .relation-contact-form .input_grey:not(:last-child) {
  margin-bottom: 16px;
}
.relations .relation-contact-form .input_grey input {
  height: unset;
}
.relations .relation-contact-form .input_grey input, .relations .relation-contact-form .input_grey textarea {
  background-color: #fff;
  padding: 20px 16px;
  line-height: 22px;
}
.relations .relation-contact-form .input_grey:hover label {
  color: var(--subtitle-text);
}
.relations .relation-contact-form .input_grey label {
  color: var(--subtitle-text2);
  font-weight: 400;
  transition: 0.3s;
  padding: 20px 16px;
  font-size: 16px;
  line-height: 22px;
}
.relations .relation-contact-form .input_grey:has(input:focus) label, .relations .relation-contact-form .input_grey:has(textarea:focus) label {
  transform: scale(1) translateY(-16px) translate(0);
  font-size: 12px;
  line-height: 16px;
}
.relations .relation-contact-form .input_grey .form-control:not(:placeholder-shown) ~ label {
  transform: scale(1) translateY(-16px) translate(0);
  font-size: 12px;
  line-height: 16px;
}
.relations .relation-contact-form .input_grey textarea {
  resize: none;
}
.relations .relation-contact-form .wpcf7-submit {
  background-color: var(--primary);
  padding: 20px 24px;
}
.relations .relation-contact-form .wpcf7-submit:hover {
  background-color: var(--primary-hover);
}
.relations .relation-contact-form .wpcf7-textarea {
  height: 167px !important;
}
@media only screen and (max-width: 767px) {
  .relations .relation-contact-form .wpcf7-textarea {
    height: 176px !important;
  }
}
.relations .contact-item {
  padding: 24px;
  background-color: var(--ground-3);
}
.relations .contact-item h3 {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 16px;
}
.relations .contact-item .contact-item__link {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.relations .relations__inner {
  display: flex;
  gap: 40px;
}
.relations .relations__column {
  flex-grow: 1;
}
.relations .relations__contacts {
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 767px) {
  .relations .relations__inner {
    flex-direction: column;
  }
}

.page-header {
  margin-top: 80px;
}

.news .news__list {
  margin-top: 0;
}
@media only screen and (max-width: 576px) {
  .news .news__list__column {
    max-width: 258px;
  }
}

.testimonials .testimonials__slider {
  padding-left: 60px;
  max-width: 1440px;
  margin: unset;
}
.testimonials .testimonials__inner {
  overflow: hidden;
}
.testimonials .testimonials__inner .testimonials__slider {
  margin-right: auto;
  margin-left: auto;
}
.testimonials .swiper {
  overflow: unset;
}
.testimonials .swiper-wrapper {
  margin: auto;
  max-width: 1440px;
}
.testimonials .swiper-slide {
  max-width: 867px;
  display: flex;
  height: unset;
}
.testimonials .testimonial {
  padding-left: unset;
}
.testimonials .testimonial__inner {
  padding: 24px;
  gap: 40px;
}
.testimonials .testimonial__body {
  position: relative;
}
.testimonials .testimonial--backquote {
  margin-bottom: 24px;
}
.testimonials .testimonial__image {
  margin: 0;
}
.testimonials .testimonial__footer {
  margin-top: 24px;
  padding-left: 0;
  border: 0;
}
.testimonials .testimonial__footer .h3 {
  text-transform: unset;
  margin-bottom: 4px;
  font-size: 18px;
  line-height: 24px;
}
.testimonials .testimonial__footer .small {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}
@media only screen and (max-width: 1199px) {
  .testimonials .testimonial {
    max-width: unset;
    padding-top: 0;
  }
  .testimonials .testimonial__inner {
    flex-direction: row;
  }
  .testimonials .swiper-slide {
    max-width: 600px;
  }
}
@media only screen and (max-width: 991px) {
  .testimonials .testimonials__slider {
    padding-left: 32px;
  }
}
@media only screen and (max-width: 767px) {
  .testimonials .testimonials__slider {
    padding-left: 16px;
  }
}
@media only screen and (max-width: 630px) {
  .testimonials .testimonials__slider {
    padding-right: 16px;
  }
  .testimonials .swiper-slide {
    width: calc(100vw - 32px) !important;
  }
}
@media only screen and (max-width: 576px) {
  .testimonials .testimonial__inner {
    flex-direction: column;
  }
  .testimonials .testimonials-control {
    display: none;
  }
  .testimonials .testimonial__image {
    width: unset;
  }
  .testimonials .testimonial__image img {
    max-width: 100%;
    height: unset;
  }
}

.people-main .page-header {
  margin-top: 0;
}
.people-main .page-header__title {
  max-width: 720px;
  margin-right: auto;
  margin-left: auto;
}
.people-main .page-header__img {
  height: 448px;
  max-height: unset;
}
.people-main .page-header__inner {
  padding: 1.5rem 1.5rem 1.5rem;
}
@media only screen and (max-width: 767px) {
  .people-main {
    margin-top: 80px;
  }
}

.prog-intership {
  background-color: var(--ground-3);
}
@media only screen and (max-width: 767px) {
  .prog-intership:not(:has(.video__poster)) .text-section__imagery {
    height: unset;
  }
  .prog-intership:not(:has(.video__poster)) .text-section__imagery img {
    position: unset;
  }
}
@media only screen and (max-width: 430px) {
  .prog-intership .btn-grey-border {
    max-width: calc(100vw - 32px);
  }
}

.career {
  overflow: unset;
  margin: 0;
}
.career .career__tabs {
  gap: 24px;
  margin-top: 0;
  margin-bottom: 24px;
}
.career .career__tabs .btn {
  padding: 13px 20px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}
.career .career__tabs .btn.active {
  background-color: var(--body-text);
  color: #fff;
}
.career .career__column:nth-child(2) {
  padding: unset;
}
.career .career__inner {
  display: flex;
  gap: 40px;
  height: 100%;
}
.career .career__column-right {
  padding: unset !important;
  height: unset;
  display: flex;
  background-color: unset;
  align-items: unset;
  max-width: 527px;
  width: 100%;
}
.career .career__column-right:before {
  display: none;
}
.career .career__apply-block {
  display: flex;
  max-width: unset;
  padding: 92px 48px !important;
  position: sticky;
  background-color: var(--primary);
  top: 100px;
  margin: unset;
  height: fit-content;
}
.career .career__apply-block__img {
  width: 119px;
  bottom: 0;
  top: unset;
}
.career .career__column-tabs {
  max-width: 753px;
  width: 100%;
  padding-right: 0;
}
.career .accordion-body p {
  margin-bottom: 24px;
}
.career .accordion-body ul {
  font-weight: 400;
}
@media only screen and (max-width: 991px) {
  .career .career__inner {
    flex-direction: column;
    gap: 64px;
  }
  .career .career__column-tabs {
    max-width: unset;
    padding-bottom: 0;
  }
  .career .career__column-right {
    max-width: unset;
    margin: unset;
  }
  .career .career__apply-block {
    width: 100%;
    padding: 54px 24px !important;
  }
  .career .career__apply-block p {
    max-width: 500px;
  }
  .career .accordion {
    gap: 24px;
  }
}
@media only screen and (max-width: 767px) {
  .career .career__inner {
    gap: 40px;
  }
  .career .accordion-body {
    padding: 0 0 24px;
  }
  .career .accordion-body .btn {
    max-width: 328px;
    width: 100%;
  }
}
@media only screen and (max-width: 692px) {
  .career a.btn {
    margin-top: 16px;
  }
}
@media only screen and (max-width: 567px) {
  .career .career__tabs {
    overflow: auto;
    max-width: 100vw;
    padding-bottom: 24px;
    margin-bottom: -8px;
  }
}
@media only screen and (max-width: 430px) {
  .career .career__tabs {
    flex-direction: column;
    padding-bottom: 40px;
    gap: 24px;
  }
  .career .career__tabs .btn {
    width: 100%;
  }
  .career .career__apply-block__inner .btn-white-border {
    width: 100%;
    justify-content: center;
  }
  .career .accordion-body .btn {
    max-width: 416px;
  }
}

.modal-apply .modal-header .btn-close {
  margin-right: -8px;
}
.modal-apply .input:has(input:focus) .form-floating input {
  border: 1px solid var(--primary);
}
.modal-apply .input:has(input:focus) .input-group {
  border: 1px solid var(--primary);
}
.modal-apply .input:has(input:focus) .input-group .form-floating input {
  border: none;
}
.modal-apply .input:has(input:focus) label {
  color: var(--subtitle-text);
  font-size: 12px;
  line-height: 16px;
}
.modal-apply .input:has(input:focus) .form-control::placeholder {
  color: var(--subtitle-text2);
}
.modal-apply .input_file.input_grey input {
  border-right: none;
}
.modal-apply .input_file.input_grey .input-group .btn {
  background-color: transparent;
  border-left: none;
}
.modal-apply .modal-header {
  padding: 24px;
}
.modal-apply .modal-body {
  padding: 0 24px 24px;
}
.modal-apply .form-actions .btn {
  padding: 20px;
  width: 100%;
}

.main-page-company .wine-desc {
  margin-bottom: 24px;
}
.main-page-company .wine__item_content {
  overflow: hidden;
  padding-top: 16px;
  padding-bottom: 8px;
}
.main-page-company .wine__item_content:hover .wine__item__img {
  transform: scale(1.5);
}
.main-page-company .wine__item_content .wine__item__img {
  transition: all 0.4s !important;
}