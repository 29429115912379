:root {
  --primary: #202F61;
  --primary-hover: rgba(29, 42, 87, 1);
  --secondary: rgba(190, 150, 110, 1);
  --body-text: rgba(39, 39, 40, 1);
  --ground-3: rgba(246, 247, 248, 1);
  --black-t10: rgba(10, 9, 8, 0.1);
  --subtitle-text2: rgba(39, 39, 40, 0.5);
  --dark-accent: rgba(10, 9, 8, 1);
  --white: rgba(255, 255, 255, 1);
  --white-t10: rgba(255, 255, 255, 0.1);
  --white-t50: rgba(255, 255, 255, 0.5);
  --white-t70: rgba(255, 255, 255, 0.7);
  --subtitle-white: rgba(255, 255, 255, 0.7)
}

.c-red{
  color: rgba(224, 40, 43, 1);
}
.c-green{
  color: rgba(81, 193, 79, 1);
}

body {
  background-color: #fff;
  color: var(--body-text);
  overflow-x: hidden;
}

.grecaptcha-badge {
  position: fixed;
  right: 0;
  visibility: hidden;
  width: unset;
}


// Base

.btn {
  border-radius: 0;
  padding: 12px 12px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
}
.btn-grey-border, .btn-white-border {
  padding: 12px 12px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  align-self: center;
  display: flex;
  align-items: center;
  line-height: 22px;
  gap: 12px;
  transition: .3s;
  &:hover {
    color: #fff;
    background-color: var(--primary-hover);
    text-decoration: none;
  }
}
.btn-grey-border {
  width: max-content;
  padding: 12px 12px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  border: 1px solid var(--black-t10);
  align-self: center;
  align-items: center;
  line-height: 22px;
  gap: 12px;
  color: var(--body-text);
  transition: .3s;

  &:hover {
    color: #fff;
    background-color: var(--primary-hover);
    text-decoration: none;
  }

  span {
    margin: 0 !important;
    padding: 0 !important;
    border: 0 !important;
  }
}
.btn-read-more {
  padding: 20px 24px;
  @media only screen and (max-width: 767px) {
    max-width: 328px;
    width: 100%;
    justify-content: center;
  }

}
.btn-white-border {
  width: max-content;
  border: 1px solid var(--white-t50);
  padding: 12px 20px;
  color: var(--white);
  background-color: transparent;

  &:hover {
    color: var(--body-text);
    background-color: var(--white);
  }

  span {
    margin: 0 !important;
    padding: 0 !important;
    border: 0 !important;
  }
}
.btn-primary {
  background-color: var(--primary);
  color: #fff;

  &:hover {
    background-color: var(--primary-hover);
    color: #fff;
  }
}

.mra {
  margin-right: auto;
}
.mla {
  margin-left: auto;
}

.section {
  padding-top: 80px;
  padding-bottom: 80px;

  margin-top: 0 !important;
  margin-bottom: 0 !important;

  .h2 {
    font-size: 36px;
    line-height: 44px;
  }

  &:not(:has(.section-header)) {
    .h2 {
      margin-bottom: 48px;

    }
  }

  @media only screen and (max-width: 1199px) {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  @media only screen and (max-width: 991px) {
    padding-top: 48px;
    padding-bottom: 48px;

    &:not(:has(.section-header)) {
      .h2 {
        margin-bottom: 24px;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .h2 {
      font-size: 32px;
      line-height: 40px;
    }
  }
}

.section-header {
  margin-bottom: 48px;
  .section-subtitle {
    margin-top: 16px;
  }

  .h2{
    margin-bottom: 0;
  }

  &:has(p){
    .h2{
      margin-bottom: 16px;
    }
  }

  &:has(.swiper-slider-control) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media only screen and (max-width: 991px) {
    margin-bottom: 24px;
  }
}

.text-section {
  &:not(:has(.video__poster)){
    @media only screen and (max-width: 767px) {
      .text-section__imagery {
        height: 480px;
        position: relative;
        overflow: hidden;

        img {
          position: absolute;
          top: 0;
          bottom: 0;
          width: 660px;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

}

@media only screen and (min-width: 992px) {
  .text-section__inner {

    align-items: unset;
  }

  .h2 {
    font-size: 36px;
    line-height: 44px;
  }

  .text-section__text p {
    color: var(--body-text);
  }
}


.container-base {
  max-width: 1320px + 60px * 2;
  padding-left: 60px;
  padding-right: 60px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  @media only screen and (max-width: 1199px) {
    padding-left: 48px;
    padding-right: 48px;
  }

  @media only screen and (max-width: 991px) {
    padding-left: 32px;
    padding-right: 32px;
  }
  @media only screen and (max-width: 767px) {
    padding-left: 16px;
    padding-right: 16px;
  }

}

.container-nopad {
  max-width: 1368px;
  margin: 0 auto;
}


.input .form-floating {
  input, select, textarea {
    background-color: transparent;
    height: 64px !important;
    padding: 21px 16px;
    border: 1px solid var(--black-t10);
    border-radius: 0;

  }

  select + label{
    display: none;
  }

  label {
    font-size: 16px;
    font-weight: 400;
    padding-top: 22px;
    padding-left: 16px;
    text-transform: initial;

  }

  &:has(input:not(:placeholder-shown)) {
    label {
      color: var(--subtitle-text);
      font-size: 12px;
      line-height: 16px;
    }

  }
}

// tabs
.tabs-wrapper {
  border: none;

  .tab-content {
    margin-top: 32px;
  }
}

.tabs-wrapper .nav-tabs .nav-link {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  text-transform: lowercase;
  color: var(--subtitle-text2);
  &::first-letter{
    text-transform: uppercase;
  }

  padding: 6px 0;

  &.active {

  }
}

//swiper
.swiper-pagination {
  //gap: 8px;
  //flex-wrap: wrap;
}

//.swiper-pagination-bullet {
//  max-width: 32px !important;
//  flex-shrink: 1;
//  display: flex;
//  width: 100% !important;
//  @media only screen and (max-width: 991px) {
//    flex: unset;
//  }
//
//}


.swiper-button {
  position: relative;
  top: 13px;
  right: unset;
  left: unset;
  height: 48px;
  width: 48px;

  svg {
    stroke: #272728;
  }

  &:hover {
    stroke: white;
  }
}

.swiper-slider-control {
  display: flex;
  gap: 8px;
}


.accordion-button {
  background-color: var(--ground-3);
  font-weight: 500;
  text-transform: initial;
  padding: 16px;

  &:not(.collapsed) {
    background-color: var(--ground-3);
  }

  &:after {
    width: 32px;
    height: 32px;
    background-size: 32px;
    background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 12L16 20L24 12' stroke='%23272728' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }

  &:not(.collapsed):after {
    background-size: 32px;
    background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 12L16 20L24 12' stroke='%23272728' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    transform: rotate(-180deg);
  }
}

.accordion-collapse {
  padding-top: 24px;
}


body #moove_gdpr_cookie_info_bar{
  border-radius: 0 !important;
  left: unset!important;
  right: 16px!important;
  border: 1px solid #e2e2e2!important;
  .moove-gdpr-info-bar-container .moove-gdpr-info-bar-content{
    a.mgbutton, button.mgbutton{
      border-radius: 0!important;
    }
    .change-settings-button{
      border: 1px solid #d3ced2!important;
      box-shadow: unset!important;
    }
  }

  @media only screen and (max-width: 430px) {
    left: 0!important;
    width: 100%!important;
    max-width: unset!important;
    right: 0!important;
    .moove-gdpr-info-bar-container .moove-gdpr-info-bar-content{
      a.mgbutton, button.mgbutton{
        padding: 12px 16px!important;
        width: calc(50% - 8px)!important;
      }
    }

  }

}
