/*/ Section Hero /*/
.hero {
  padding-bottom: 100px;

  .swiper .btn-primary {
    padding: 20px 24px;
    font-size: 18px;
    line-height: 24px;
  }

  .h1 {
    font-size: 48px;
    line-height: 56px;

  }

  .hero__slide__inner {
    gap: 48px;
  }

  .hero__leading {
    margin-top: 0;
    font-weight: 400;
  }

  .hero-slide {
    max-width: 868px;
  }

  @media only screen and (max-width: 767px) {

  }


  @media only screen and (max-width: 767px) {
    .h1 {
      text-transform: initial;
      font-size: 32px;
      line-height: 40px;
    }
    .hero__slide__inner {
      gap: 24px;
    }

    .hero__slide {
      padding-bottom: 142px;
      padding-top: 198px;

    }
  }

  @media (max-width: 1200px) {
    .hero__slide {
      max-width: 75%;
    }

  }
  @media (max-width: 767px) {
    .hero__slide {
      max-width: 100%;
    }

  }
  //@media (min-width: 768px) {
  //  .hero__slide {
  //    max-width: 50%;
  //    padding-bottom: 3rem;
  //  }
  //}


}

.hero__stock {
  top: unset;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--primary);
  padding-top: 0;

  .hero__stock__container {
    width: 100%;
    padding-left: 0;
  }


  .hero-stock-wrapper {
    overflow: auto;
    display: flex;
  }

  .hero__stock__header {
    margin-right: 40px;
  }

  .hero__stock__inner {
    display: flex;
    padding: 8px 0;
    background: unset;
    backdrop-filter: unset;
    width: 1320px;
    flex-shrink: 0;
  }

  .hero__stock__list {
    display: flex;
    flex-grow: 111;
    margin-top: 0;
    gap: 24px;
  }

  .hero__stock__list__item {
    display: flex;
    flex-direction: column;
    max-width: 187px;
    width: 100%;
    height: 84px;
    padding: 0;
    justify-content: center;
    position: relative;
    border-bottom: none;

    &:first-child {
      flex-shrink: 0;
    }

    &:not(:last-child):after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 1px;
      background-color: rgba(255, 255, 255, 0.1)
    }

    span {
      margin: unset;
      text-align: unset;
    }
  }

  .hero__stock__list__label {
    margin-bottom: 8px !important;
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
  }
  .hero__stock__list__value{
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
  }

  .hero__stock__btn {
    margin-left: 40px;

    border: 1px solid rgba(255, 255, 255, 0.5);

    &:hover {
      svg {
        color: white
      }
    }

    span {
      margin: 0;
    }
  }


}


//.homepage{
//  *{
//    outline: 1px solid red
//  }
//}


//sections

.fin-stats {
  margin-bottom: 0;
  margin-top: 0;

  background-color: var(--ground-3);

  .h2 {
    margin-bottom: 16px;
  }

  .fin-stats__inner {
    gap: 40px;
  }

  .fin-stats__column {
    display: flex;
    flex-direction: column;
  }

  .fin-stats__column:not(:first-child) {
    background-color: #fff;
    padding: 24px 24px 40px;
  }

  .btn-grey-border {
    align-self: flex-start;
    padding: 13px 20px;
  }

  .fin-stats__subtitle {
    font-size: 16px;
    line-height: 22px;
  }



  @media only screen and (max-width: 1199px) {
    .fin-stats__inner {
      flex-wrap: wrap;
      display: flex;
    }

    .fin-stats__column {
      width: calc(50% - 20px);
    }
  }

  @media only screen and (max-width: 767px) {
    .fin-stats__inner {
      gap: 24px;

      .fin-stats__column {
        width: calc(50% - 12px);
      }
    }

    .fin-stats__value {
      font-size: 36px;
      line-height: 44px;
    }
  }

  @media only screen and (max-width: 576px) {
    .fin-stats__inner {
      gap: 40px;

      .fin-stats__column {
        width: 100%;
      }
    }

  }

}

.mission {
  overflow: hidden;
  margin-top: 0;
  margin-bottom: 0;

  .container-base {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .h2 {
    margin-bottom: 16px;
  }

  .mission__inner {
    display: flex;
    flex-direction: column;
    align-items: unset;
  }

  .mission__column_info {
    display: flex;
    width: 100%;
    padding-right: 0;

    .mission__block {
      display: flex;
      width: 50%;
      flex-direction: column;
      max-width: unset;
      padding: 48px 60px;
      margin-bottom: 0;
      color: white;
      position: relative;

      &:first-child {
        background-color: var(--primary);

        &:before {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          background-color: var(--primary);
          width: 50vw;
          z-index: 0;
          left: -50vw;
        }
      }

      &:nth-child(2) {
        background-color: var(--secondary);

        &:after {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          background-color: var(--secondary);
          width: 50vw;
          z-index: 0;
          right: -50vw;

        }
      }
    }
  }

  @media (min-width: 768px) {
    .mission__block {
      margin-bottom: unset;
    }
  }

  @media only screen and (max-width: 1199px) {
    .mission__column_info {
      .mission__block {
        padding-left: 32px;
        padding-right: 32px;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .mission__column_info {
      flex-direction: column;

      .mission__block {
        width: 100%;
        padding: 48px 16px;
      }
    }
  }

}

.our-values {
  .mission__list {
    background-color: unset;
    padding: 0;

    ul {
      display: flex;
      width: 100%;
      max-width: unset;
      gap: 24px;

    }

    li {
      display: flex;
      flex-direction: column;
      padding-left: 0;
      background-color: unset;
      margin-bottom: 0;

      span {
        font-size: 48px;
        line-height: 56px;
        font-weight: 700;
        color: var(--secondary);
        display: block;
        margin-bottom: 24px;
      }

      &:before {
        display: none;
      }

      strong {
        margin-bottom: 8px;
        font-size: 18px;
        font-weight: 700;
      }
    }


    @media only screen and (max-width: 1199px) {
      ul {
        display: flex;
        flex-wrap: wrap;

      }

      li {
        max-width: calc(50% - 20px);
        width: 100%;
      }
    }

    @media only screen and (max-width: 576px) {
      ul {
        flex-direction: column;
      }
      li {
        width: 100%;
        max-width: unset;
      }
    }


  }

}

.awards {
  margin-top: 0;
  margin-bottom: 0;

  .awards__slider {
    margin: unset !important;

    .awards__img {
      transition: .3s;
    }

    .swiper-slide {
      overflow: hidden;
      width: unset;

    }

    .awards__img:hover {
      transform: scale(1.1);
    }

  }

  @media only screen and (max-width: 767px) {
    .awards__img {
      height: 328px !important;
      width: 100%;
    }
  }

}

.news {
  margin-top: 0;
  margin-bottom: 0;
  background-color: var(--ground-3);

  .news__list {
    display: flex;
    gap: 40px;
    overflow: auto;
  }

  .news__list__column {
    max-width: 300px;
    width: 100%;
    flex-shrink: 0;
  }

  .news__item {

  }

  .news__item__date {
    color: var(--subtitle-text2);
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
  }

  .news__item__title {
    font-size: 18px;
    line-height: 20px;
    font-weight: 500;
  }

  .news__item__img {

  }

  .btn-more-news {
    margin-right: auto;
    margin-left: auto;
    width: max-content;
    padding: 20px 24px;
    font-size: 18px;
    line-height: 24px;
  }

  @media only screen and (max-width: 767px) {
    .tab-content {
      overflow: hidden;
    }
    .btn-more-news {
      width: 100%;
      max-width: 328px;
    }
  }


}

.membership {
  margin: 0;


  @media only screen and (max-width: 767px) {
    .membership__inner{
      display: flex;
      flex-wrap: wrap;
      gap: 40px;
      margin: unset;
      padding: unset;
    }

    .membership__column{
      width: calc(100% / 2 - 20px);
      min-height: 50px;
    }

    .membership__img{

    }
  }
}
.main-page-home{
  .logotypes {
    background-color: var(--ground-3);
    padding: 24px 0;
    overflow: hidden;

    .logotypes__inner {
      overflow: auto;
    }
  }

}






