.header {
  padding: 0;

  &.scrolled, &.homepage.scrolled {
    background-color: var(--primary);

    &:before {
      content: "";
      position: absolute;
      backdrop-filter: unset;
    }
  }


  .header__logo {
    &:hover {
      color: white;
    }
  }

  .header__nav__list {
    gap: 48px;
  }

  .header__nav__item {
    margin-left: 0;
  }

  .header__logo {
    width: 185px;
    margin-right: unset;
  }

  .header__actions {
    width: 185px;
    margin-left: unset;
    display: flex;
    justify-content: flex-end;

    .dropdown-menu {
      min-width: 120px;
    }

    .dropdown-item.active {
      svg {
        display: none;
      }
    }
  }

  .header__search {
    background-color: #fff;
    padding: 15px 12px 15px 24px;
    margin-left: 0;
    height: 48px;

    .header__search-ico {
      padding-right: 16px;
      //border-right: 2px solid var(--body-text);
      margin-right: 4px;
      display: flex;
    }

    .search-clear {

    }

    .input {
      display: flex;
      align-items: center;

      &.has-error .form-control {
        border: none;
      }

      label {
        display: none;
      }

      input {
        padding: 0;
        height: 22px;
        font-size: 16px;
        line-height: 22px;

        &::placeholder {
          color: var(--subtitle-text2) !important;
        }
      }
    }
  }

  .dropdown-menu-closer{
    display: none;
  }

  @media only screen and (max-width: 1199px) {
    .header__inner {
      justify-content: space-between;
    }

    .header__nav {
      padding: 0;
      width: 260px;
      background-color: var(--primary);
    }

    .header-nav-inner {

      height: 100%;
      padding-top: 80px;
    }

    .header__nav__item {
      border: none;
      padding-top: 20px;
      padding-bottom: 20px;

    }

    .header__nav__item:first-child {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    .header__nav__link {
      justify-content: center;
    }

  }

  @media only screen and (max-width: 767px) {

    &.homepage{
        background-color: var(--primary);
    }

    .header__nav {
      bottom: -100%;
      transform: unset!important;
      top: unset;
      height: fit-content;
      overflow: unset;
      left: 0 !important;
      right: 0!important;
      width: 100%;
      max-width: unset;
      transition: .3s;
    }
    .header-nav-inner{
      padding-top: unset;
      height: 100%;
      transition: .3s;
      max-height: calc(100vh - 200px);
      overflow: auto;
      &:has(.header__nav.active){
        display: none;
      }

    }
    .header__nav.active {

      bottom: 0;
      max-height: 80%;
      width: 100% !important;
      max-width: 100%;
      padding-bottom: 24px;
    }

    .header__nav__list{
      gap: 24px;
      flex-direction: column;
      display: flex;

    }
    .header__nav__close {
      display: flex;
      position: relative;
      height: 37px;
      right: 0;
      width: 100%;
      left: 0;
      top: unset;
      &:before{
       content:"";
       position:absolute;
        width: 36px;
        height: 6px;
        border-radius: 10px;
        background-color: var(--white-t50);
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
      }
      svg{
        display: none;
      }
    }
    .header__nav__item{
      padding-top: 12px!important;
      padding-bottom: 12px!important;
    }

    .header__actions {
      .dropdown-menu{
        bottom: -100%;
        padding-bottom: 24px;
        //padding-top: 24px;

        &:before{
          display: flex;
          position:absolute;
          height: 37px;
          right: 0;
          width: 100%;
          left: 0;
          content:"";
          top: -37px;
          background-color: #fff;
        }

        &:after{
          content:"";
          position:absolute;
          width: 36px;
          height: 6px;
          border-radius: 10px;
          background-color: var(--black-t10);
          top: -20px;
          right: 0;
          /* bottom: 0; */
          left: 0;
          margin: auto;

        }

      }

      .dropdown-menu.show{
        inset:unset !important;
        width: 100%!important;
        left: 0!important;
        right: 0!important;
        position: fixed!important;
        transform: unset!important;
        height: fit-content!important;
        bottom: 0!important;
        flex-direction: column;
        gap: 8px;

      }

      .dropdown-item{
        text-align: center;
      }
    }

    &:has(.dropdown-menu.show){
      .header-backdrop{
        opacity: 1;
        visibility: visible;
      }
    }

    .header__search{
      position: fixed;
      left: 0;
      right: 0;
      top: -100%;

      padding: 15px 16px 15px 16px;
    }

    .search-open{
      .header__search{
        top: 80px;
      }
      .header__logo{
        display: flex;
      }

    }

    .header-backdrop{
      z-index: 0;
      position: fixed;
      inset: 0;
      background-color: rgba(0, 0, 0, 0.2);
      opacity: 0;
      visibility: hidden;
      transition: .3s;
    }

    &:has(.search-open){
      .header-backdrop{
        opacity: 1;
        visibility: visible;
      }

    }
  }

}
@media only screen and (max-width: 767px) {
  .header__inner{
    transition: .3s;
  }
  .body.menu-open{
    .header__inner{
      margin-top: -100px;
      //padding: 0;
      //&:before{
      // content:"";
      // position:absolute;
      //  inset: 0;
      //  background-color: rgba(0, 0, 0, 0.2);
      //}
    }
    .header__logo, .header__actions{
      //display: none;
    }
    
  }
}

@media only screen and (max-width: 767px) {
 body{
   &:has(.header__actions .dropdown-menu.show){
     overflow: hidden;
   }
 }
}

.footer {
  padding-top: 48px;
  padding-bottom: 48px;

  .footer__row {
    padding-bottom: 48px;
    position: relative;
    margin-top: 0;

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      height: 1px;
      background-color: var(--white-t10);
    }
  }

  .footer-after-logo {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: var(--subtitle-white);
  }

  .footer__row {
    gap: 40px;
    display: flex;
    width: 100%;
  }

  .footer__column {
    max-width: 284px;
    flex-grow: 1;
  }

  .footer__column-main {
    max-width: 349px;
  }

  .footer__social {
    gap: 16px;
    margin-top: 24px;
    display: flex;
  }

  .footer__social__link {
    min-width: unset;
    min-height: unset;
    display: flex;
    width: 18px;
    height: 18px;
    color: rgba(255, 255, 255, 0.7);
    &:hover {
      color: #fff;
    }
  }

  .footer__menu {
    gap: 16px;
  }

  .footer__menu__link {
    color: #fff;
    font-size: 16px;
    line-height: 22px;

  }
  
  


  .footer__title {
    color: var(--subtitle-white);
    margin-bottom: 24px;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
  }

  .footer__contact {
    input {
      background-color: var(--white-t10);
      width: 100%;
      border-bottom: 0;
      height: 48px !important;
      padding: 13px 16px;
    }

    .input-group {
      position: relative;
      margin-bottom: 0;
      border: 0 !important;
    }

    .form-floating {
      &:has(input:focus) {
        input::placeholder {
          //color: var(--subtitle-text2);
        }
      }

      &:has(input:focus), &:has(input:not(:placeholder-shown)) {
        label {
          font-size: 12px;
        }
      }
    }

    .form-floating > label {
      left: 16px;
      font-size: 16px;
      line-height: 22px;
      font-weight: 400;
      color: var(--subtitle-white);
      padding: 13px 0;
      transition: .3s;
    }

    [type="submit"] {
      position: absolute;

      margin-top: auto;
      margin-bottom: auto;
      top: 0;
      bottom: 0;

      right: 0;
      width: 48px;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.16675 10H15.8334' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M10 4.16699L15.8333 10.0003L10 15.8337' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }
  }

  .footer-contacts-us-list {
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    div {
      display: flex;
      gap: 8px;
    }

    a, span {
      color: #fff;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
    }

    svg {
      flex-shrink: 0;
    }
  }

  .footer__copyright {
    margin-top: 0;
    color: var(--subtitle-white);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  .footer-bottom {
    padding-top: 48px;

    display: flex;
    justify-content: space-between;

    a {
      color: var(--subtitle-white);
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
  }

  .footer-by {
    font-size: 14px;
    line-height: 20px;

  }


  @media only screen and (max-width: 991px) {
    .footer__row {
      flex-wrap: wrap;
      gap: 24px;
    }

    .footer__column {
      width: calc(100% / 2 - 16px);
    }

    .footer-col-menu-help {
      width: 100%;
      max-width: unset;
    }
    .footer-col-menu {
      width: 100%;
      max-width: unset;

      order: 1;
    }

    .footer__menu {
      display: flex;
    }
  }

  @media only screen and (max-width: 650px) {
    .footer__row {
      flex-direction: column;
    }
    .footer__row {
      gap: 40px;
    }

    .footer__column {
      width: 100%;
    }

    .footer-col-menu {
      order: unset
    }
  }

  @media only screen and (max-width: 576px) {
    .footer__menu {
      display: initial;
    }

    .footer-bottom {
      flex-direction: column;
      gap: 24px;
      align-items: center;
    }
  }


}