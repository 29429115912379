.main-page-company{
  .wine-desc{
    margin-bottom: 24px;
  }
  .wine__item_content{
    overflow: hidden;
    padding-top: 16px;
    padding-bottom: 8px;
    &:hover{
      .wine__item__img{
        transform: scale(1.5);
      }
    }
    .wine__item__img{
      transition: all .4s !important;
    }

  }
}